import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';


import Newsletter from '../../Common/Newsletter';

import footerLogo1 from '../../../assets/img/logo/logo.png';
import upworkg from '../../../assets/img/up-grey.png';
import upwork from '../../../assets/img/up.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p>Yautik Technology Pvt Ltd. is a budding IT, Electronics and Consulting start-up that provides both B2B and B2C services to its clients round the globe. The scope of agency work stretches from Technology development to Marketing strategies to Content development to Research assistance. The motto of company manifest a very loud and clear message- 
                                Yautik Technology is not only your service provider by profession but a "Digital Companion" by heart.</p>
                            </div>
                            <ul className="footer-social md-mb-30">
                                    <li>
                                        <a href="https://www.linkedin.com/company/yautik/"><i className="fa fa-linkedin"></i></a>
                                    </li>
                                    <li >
                                        <a href="https://www.upwork.com/agencies/yautiktechnology/" target='blank'><img className="upwork-g" src={upworkg} alt="upwork" />
                                            
                                        </a>
                                    </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">IT Services</h3>
                            <ul className="site-map">
                                <li><Link to="/ServiceDetails/SoftwareDevelopment">Software Development</Link></li>
                                <li><Link to="/ServiceDetails/WebDevelopment">Web Development</Link></li>
                                <li><Link to="ServiceDetails/AppDevelopment">Mobile App Development</Link></li>
                                <li><Link to="/ServiceDetails/Cloud">Cloud and DevOps</Link></li>
                                 <li><Link to="/ServiceDetails/Product">Product Development</Link></li>
                                
                                <li><Link to="/ServiceDetails/Custom">Custom Software Development</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Contact Info</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">105, Sri Vignesh Apartment, Deverachikanhalli Road, IIM Post, 
                                    Bangalore, KA, India, 560076</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <Link to="#">+91-9693284773</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <Link to="#">vishal@yautiktechnology.com</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        24/7
                                    </div>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;