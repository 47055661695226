import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import serviceIcon1 from '../../assets/img/service/style1/1.png';
import serviceIcon2 from '../../assets/img/service/style1/2.png';
import serviceIcon3 from '../../assets/img/service/style1/3.png';
import serviceIcon4 from '../../assets/img/service/style1/4.png';
import serviceIcon5 from '../../assets/img/service/style1/5.png';

const Service = () => {

    return (
        <div className="rs-services main-home style2 pt-120 pb-100 md-pt-80 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text style-bg"
                    subtitle="Solutions"
                    titleClass="title title2"
                    title="Over a Years IT & Technology Solutions Includes"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1"
                            serviceImage={serviceIcon1}
                           // serviceURL="it-management"
                            subtitle="Web & Mobile App Development"
                            Text="Transform your digital presence with our versatile web and mobile app development services, where 
                            innovation meets seamless functionality to create engaging experiences across platforms."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1 active"
                            serviceImage={serviceIcon4}
                           // serviceURL="cloud-services"
                            subtitle="IT Consulting"
                            Text="Elevate your business strategies with our comprehensive IT consulting services, where expertise meets
                             innovation to optimize your technological landscape and drive sustainable growth."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1"
                            serviceImage={serviceIcon5}
                          //  serviceURL="data-security"
                            subtitle="Staff Augmentation"
                            Text="Empower your team and accelerate project delivery with our staff augmentation services, providing skilled 
                            professionals seamlessly integrated into your workforce to meet your specific needs and enhance overall productivity."

                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;