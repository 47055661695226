import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu, activeMenu } = props;

    return (
        <React.Fragment>
            <li className={parentMenu === 'home'}><Link to="" as="/">Home</Link>
            </li>
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                <Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
            </li>
            <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link href="#" as="#">Service</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/ServiceDetails/SoftwareDevelopment" className={activeMenu === "/software-development" ? "active-menu" : ""}>Software Development</Link>
                    </li>
                    <li>
                        <Link to="/ServiceDetails/WebDevelopment" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
                    </li>
                    <li>
                        <Link to="/ServiceDetails/AppDevelopment" className={activeMenu === "/AppDevelopment" ? "active-menu" : ""}>App Development</Link>
                    </li>
                    <li>
                        <Link to="/ServiceDetails/Cloud" className={activeMenu === "/cloud-and-devops" ? "active-menu" : ""}>Cloud and DevOps</Link>
                    </li>
                    <li>
                        <Link to="/ServiceDetails/Product" className={activeMenu === "/project-design" ? "active-menu" : ""}>Project Development</Link>
                    </li>
                    <li>
                        <Link to="/ServiceDetails/Custom" lassName={activeMenu === "/data-center" ? "active-menu" : ""}>Custom Development</Link>
                    </li>
                </ul>
            </li>
            
            <li className={parentMenu === 'industries' }>
                <Link to="/industries" className={activeMenu === "/industries" ? "active-menu" : ""}>Industries</Link>
                {/* <ul className="sub-menu">
                    <li>
                        <Link to="/industries-det" className={activeMenu === "/Industries" ? "active-menu" : ""}>Blog</Link>
                    </li>
                    <li>
                        <Link to="/blog/blog-details" className={activeMenu === "/blog-details" ? "active-menu" : ""}>Industries details</Link>
                    </li>
                </ul> */}
            </li>
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;