import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';



//Custom Components

import Home from '../pages/home';

import About from '../pages/about';
import Team from '../pages/team';
import TeamDetails from '../pages/team/team-details';
import Industries from '../pages/blog';
import IndustriesDetails from '../pages/blog/blog-details';
import Services from '../pages/service';
import ServiceThree from '../pages/service/service-3';
import ServiceDetails from '../pages/service/service-details';
// import SoftwareDevelopment from '../pages/service/software-development';
// import WebDevelopment from '../pages/service/web-development';
import AnalyticSolutions from '../pages/service/analytic-solutions';
import cloudAndDevops from '../pages/service/cloud-and-devops';
import ProjectDesign from '../pages/service/project-design';
import DataCenter from '../pages/service/data-center';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'

// blog-- industries-----------------
// import ind from '../pages/blog/web-development';
import IndDetails from '../pages/industries'
//=============== below adding for service

import AppDevelopment from '../pages/ServiceDetails/AppDevelopment';
import WebDevelopment from '../pages/ServiceDetails/WebDevelopment';
import SoftwareDevelopment from '../pages/ServiceDetails/SoftwareDevelopment';
import Cloud from '../pages/ServiceDetails/Cloud';
import Custom from '../pages/ServiceDetails/Custom';
import Product from '../pages/ServiceDetails/Product';



//=======

const App = () => {
    return (
        <div className='App'>
            <Router>  
                <LoadTop />          
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />
                    
                    <Route path="/about" component={About} />
                    <Route path="/service" exact component={Services} />
                    <Route path="/service/service-3" component={ServiceThree} />
                    <Route path="/service/service-details" component={ServiceDetails} />
                    <Route path="/service/software-development" component={SoftwareDevelopment} />
                    <Route path="/service/web-development" component={WebDevelopment} />
                    <Route path="/service/analytic-solutions" component={AnalyticSolutions} />
                    <Route path="/service/cloud-and-devops" component={cloudAndDevops} />
                    <Route path="/service/project-design" component={ProjectDesign} />
                    <Route path="/service/data-center" component={DataCenter} />
                    <Route path="/team" exact component={Team} />
                    <Route path="/team/team-details" component={TeamDetails} />
                    <Route path="/industries" exact component={Industries} />
                     <Route path="/industries/industries-details" component={IndustriesDetails} /> 
                    <Route path="/contact" component={Contact} />
                    <Route path="/pages/industries" component={IndDetails}/>

                    {/* for all services */}

                    <Route path="/ServiceDetails/AppDevelopment" component={AppDevelopment} />
                    <Route path="/ServiceDetails/WebDevelopment" component={WebDevelopment} />
                    <Route path="/ServiceDetails/SoftwareDevelopment" component={SoftwareDevelopment} />
                    <Route path="/ServiceDetails/Cloud" component={Cloud} />
                    <Route path="/ServiceDetails/Product" component={Product} />
                    <Route path="/ServiceDetails/Custom" component={Custom} />
                    <Route component={Error} />
                </Switch>
            </Router>
            
        </div>
    );
}

export default App;
