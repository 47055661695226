import React from 'react';
import { Link } from 'react-router-dom';
import Brand from '../../components/Common/Brand'
import CounterStyleThree from '../../components/Elements/Counter/CounterStyleThree';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';
// import Services from '../../components/Pricing/SserviceDetails';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';

// Service Image
import serviceIcon1 from '../../assets/img/service/style7/1.png';
import serviceIcon2 from '../../assets/img/service/style7/2.png';
import serviceIcon3 from '../../assets/img/service/style7/3.png';

import aboutImg1 from '../../assets/img/about/1.jpg';

/////////////////////////\\\\\\\\\\\\

import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/img/breadcrumbs/3.jpg';


// Choose Us Image
import whyChooseUsImg from '../../assets/img/choose/3.png';

const SoftwareDevelopment = () => {
    return (
        <React.Fragment>

        <Header
                parentMenu='page'
                secondParentMenu='Custom Development'
                activeMenu='/services'
            />
            {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="Custom Development"
                    titleClass="page-title"
                    pageName="Services"
                    breadcrumbsImg={bannerbg}
                />
                {/* breadcrumb-area-start */}


            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="Custom Development"
                                titleClass="title"
                                title="Tailored Solutions, Unparalleled Results"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Yautik Technology, We specialize in crafting custom software that perfectly aligns with your unique business needs, 
                                enhancing efficiency, productivity, and innovation. Whether you're seeking a fully customized application, integration solutions, 
                                or legacy system modernization, our expert team is dedicated to turning your vision into a reality."
                                secondDescClass="desc"
                                titleClass1="title1"
                                title1="Key Offerings : "
                                subtitleClass1="subtitle1"
                                subtitle1="1. Full-Cycle Custom Software Development "
                                secondDescription=" Experience end-to-end custom software development tailored to your specific requirements. From conceptualization and design to development, 
                                testing, and deployment, we guide you through every phase of the development lifecycle."
                                // 2nd para
                                subtitleClass2="subtitle2"
                                subtitle2="2. Scalable and Flexible Architectures"
                                subdesc2="desc2"
                                desc2="Future-proof your business with our scalable and flexible software architectures. Our solutions are designed to evolve with your growing business, 
                                adapting seamlessly to changing needs and technological advancements."
                                // 3rd para
                                subtitleClass3="subtitle3"
                                subtitle3="3. Integration Excellence"
                                subdesc3="desc3"
                                desc3="Streamline your operations with seamless integrations. Our custom software solutions are built to integrate with existing systems, ensuring a 
                                cohesive and efficient IT ecosystem within your organization."
                                // 4th para
                                subtitleClass4="subtitle4"
                                subtitle4="4. User-Centric Design"
                                subdesc4="desc4"
                                desc4="Prioritize user experience with our user-centric design approach. We create intuitive and engaging interfaces that enhance user adoption and 
                                satisfaction, ensuring your software is a joy to use."
                                 // 5th para
                                subtitleClass5="subtitle5"
                                subtitle5="5. Agile Development Methodologies"
                                subdesc5="desc5"
                                desc5="Embrace agility in software development with our iterative and flexible methodologies. Our collaborative approach allows for continuous feedback, 
                                quick adaptations, and ensures the final product meets your evolving business needs."



                                // subtitle1="subtitle2"
                                // subtitle1=""
                                
                                
                                // desc1= "  "
                                // desc2="abcd"

                            />
                            {/* Section Title End */}
                            <div className="btn-part mt-46">
                                <Link to="/contact">
                                    <a className="readon learn-more">Contact Us</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="Software Details" />
                            </div>
                        </div>
                    </div>
                    {/*  Below of Description start */}
                    <div className="rs-services style5 pt-120 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Expert Peoples"
                                        Text="Empower your project with our team of seasoned App development experts, 
                                        bringing a wealth of experience. "
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="First Growing Process"
                                        Text="Embark on a transformative journey with our App development team, guiding 
                                        you through the initial growth phase with agile methodologies. "
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Creative Ideas"
                                        Text="Embark on a captivating voyage through our App development atelier, where 
                                        creativity converges with code. "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}

            {/* Why Choose US Section Start */}
            <div className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-46 md-pr-15 md-mb-50">
                            <div className="choose-img">
                                <img className="main" src={whyChooseUsImg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text choose"
                                subtitle="Why Choose Us"
                                titleClass="title pb-20"
                                title="We Are Increasing Business Success With Technology "
                                descClass="desc"
                                description="We understand that one size does not fit all. Our team meticulously tailors every aspect of
                                 your software to ensure it precisely meets your unique requirements."
                            />
                            {/* Section Title End */}
                            <div className="row">
                                <div className="col-lg-10">
                                    {/* Skill Bar Start */}
                                    <div className="rs-skillbar style1 modify1">
                                        <div className="cl-skill-bar">
                                            <ProgressBar
                                                completed={92}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#03228f"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Software Development"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={80}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#03228f"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Cyber Security"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={95}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#03228f"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Artificial Intelligence"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={78}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#03228f"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Web Development"
                                                ProgressParent="main-div"
                                            />
                                        </div>
                                    </div>
                                    {/* Skill Bar End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Choose US Section End */}

            {/* Counter Section Start */}
            <CounterStyleThree />
            {/* Counter Section Start */}

           

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
             <Footer />
            <SearchModal />
        </React.Fragment>

    );
}

export default SoftwareDevelopment