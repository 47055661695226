import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlip from '../../components/Service/SingleServiceFlip';

import serviceIcon1 from '../../assets/img/service/main-home/1.png';
import serviceIcon2 from '../../assets/img/service/main-home/2.png';
import serviceIcon3 from '../../assets/img/service/main-home/3.png';
import serviceIcon4 from '../../assets/img/service/main-home/4.png';
import serviceIcon5 from '../../assets/img/service/main-home/5.png';
import serviceIcon6 from '../../assets/img/service/main-home/6.png';

import shapeImg from '../../assets/img/service/s2.png';

const ServiceFlip = () => {

    return (
        <div id="rs-service" className="rs-services main-home style2 pt-120 pb-94 md-pt-80 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text style-bg"
                    subtitle="Services"
                    titleClass="title"
                    title="We Are Offering All Kinds of IT Solutions Services"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon1}
                            serviceURL="software-development"
                            Title1="Software Development"
                            TextDesc="Elevate your business with our comprehensive software development services, delivering tailored solutions that seamlessly
                             integrate cutting-edge technology to meet your unique needs and drive success."
                            ButtonClass1="readon view-more"
                            ButtonText1="Learn More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon2}
                            serviceURL="web-development"
                            Title2="Web Development"
                            TextDesc="Transform your online presence with our web development services, where expertly crafted websites and web applications
                             are designed to captivate users, enhance functionality, and propel your digital goals to new heights.
"
                            ButtonClass2="readon view-more"
                            ButtonText2="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon3}
                            serviceURL="analytic-solutions"
                            Title3="Mobile App Development"
                            TextDesc="Empower your business with our mobile app development services, creating bespoke and user-centric applications that elevate your brand, 
                                        enhance customer engagement, and thrive in the dynamic mobile ecosystem."
                            ButtonClass3="readon view-more"
                            ButtonText3="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon4}
                            serviceURL="cloud-and-devops"
                            Title4="Cloud and DevOps"
                            TextDesc="Unlock unparalleled efficiency and scalability for your business through our comprehensive cloud and DevOps services, seamlessly integrating 
                            cutting-edge technologies to optimize workflows, enhance collaboration, and propel your digital transformation."
                            ButtonClass4="readon view-more"
                            ButtonText4="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon5}
                            serviceURL="product-design"
                            Title5="Product Development"
                            TextDesc="Elevate your product vision into reality with our innovative Product Development services, where creativity converges with user-centric principles 
                            to craft visually stunning and functionally superior solutions that resonate with your target audience."

                            ButtonClass5="readon view-more"
                            ButtonText5="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon6}
                            serviceURL="data-center"
                            Title6="Custom Software Development"
                            TextDesc="our custom development services deliver innovative solutions that seamlessly align with your business objectives, providing a competitive 
                            edge through personalized and scalable software solutions."
                            ButtonClass6="readon view-more"
                            ButtonText6="Read More"
                        />
                    </div>
                </div>
            </div>
            <div className="shape-animation">
                <div className="shape-part">
                    <img className="dance" src={shapeImg} alt="images" />
                </div>
            </div>
        </div>
    );
}

export default ServiceFlip;