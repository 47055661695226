import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';


import quote from '../../assets/img/testimonial/quote.png';
import author1 from '../../assets/img/testimonial/1.jpg';
import author2 from '../../assets/img/testimonial/2.jpg';
import author3 from '../../assets/img/testimonial/3.jpg';
import author4 from '../../assets/img/testimonial/4.jpg';
import author5 from '../../assets/img/testimonial/5.jpg';

function Testimonial() {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,

            },
            992: {
                items: 2,
                stagePadding: 0,

            },
            1200: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div className="rs-testimonial main-home gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text"
                        subtitle="Testimonial"
                        titleClass="title testi-title"
                        title=" What Saying Our Customers"
                        effectClass="heading-line"
                    />
                    <OwlCarousel options={options} >
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="Wes Jhonson" 
                            Designation="CEO Fusion Code IP Services" 
                            Description="Very Attentive towards his works i really like his delivery module with 
                        quality of work i will recommend  to work with him and his company"
                        />
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author2}
                            Title="Issey Morita" 
                            Designation="Yokohama Design Bureau" 
                            Description="I have worked with Vishal and his  Team   all are a great web developer. He has lots of experience of this kind of job and I was happy with his deliverables and Vishal did  a good managing and 
                        deliver me the output within time frame.I hope we can work together again! Thank you."
                        />
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author3}
                            Title="Awin" 
                            Designation="SR Developer" 
                            Description="It was really amazing as Anuj and his team worked and completed the work before the time and within the expectation of work hour as well, 
                        I will strongly recommend to hire and i will hire him again soon for a new work."
                        />
                        
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Testimonial