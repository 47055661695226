import React from 'react';
import { Link } from 'react-router-dom';
import Brand from '../../components/Common/Brand'
import CounterStyleThree from '../../components/Elements/Counter/CounterStyleThree';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';
// import Services from '../../components/Pricing/SserviceDetails';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';

// Service Image
import serviceIcon1 from '../../assets/img/service/style7/1.png';
import serviceIcon2 from '../../assets/img/service/style7/2.png';
import serviceIcon3 from '../../assets/img/service/style7/3.png';

import aboutImg1 from '../../assets/img/about/1.jpg';

/////////////////////////\\\\\\\\\\\\

import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/img/breadcrumbs/3.jpg';


// Choose Us Image
import whyChooseUsImg from '../../assets/img/choose/3.png';

const SoftwareDevelopment = () => {
    return (
        <React.Fragment>

        <Header
                parentMenu='page'
                secondParentMenu='Software Development'
                activeMenu='/services'
            />
            {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="Software Development"
                    titleClass="page-title"
                    pageName="Services"
                    breadcrumbsImg={bannerbg}
                />
                {/* breadcrumb-area-start */}


            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="Software Development"
                                titleClass="title"
                                title="Revolutionizing the Future of Software Development."
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Yautik Technology is an innovative and cutting-edge software development solution designed to streamline 
                                and elevate your development process. Whether you're a seasoned developer or a startup looking to establish your digital 
                                presence, our platform empowers you to create robust, scalable, and efficient applications with ease. "
                                secondDescClass="desc"
                                titleClass1="title1"
                                title1="Key Features : "
                                subtitleClass1="subtitle1"
                                subtitle1="1. Intuitive User Interface "
                                secondDescription=" Our user-friendly interface ensures that both beginners and experienced 
                                developers can navigate the platform seamlessly. Enjoy a smooth and efficient development experience from start to finish."
                                // 2nd para
                                subtitleClass2="subtitle2"
                                subtitle2="2. Versatile Language Support"
                                subdesc2="desc2"
                                desc2="Yautik Technology supports a wide range of programming languages, allowing you to choose the language that best fits
                                 your project requirements. From Python and JavaScript to Java MEAN, MERN ETC., we've got you covered."
                                // 3rd para
                                subtitleClass3="subtitle3"
                                subtitle3="3. Collaborative Development Environment"
                                subdesc3="desc3"
                                desc3="Facilitate collaboration among your team members with our built-in collaborative tools. Real-time editing, version 
                                control, and seamless communication make teamwork a breeze."
                                // 4th para
                                subtitleClass4="subtitle4"
                                subtitle4="4. Automated Testing and Debugging"
                                subdesc4="desc4"
                                desc4="Efficiently identify and fix bugs with our automated testing and debugging features. Ensure the reliability and 
                                stability of your code before deployment."
                                 // 5th para
                                subtitleClass5="subtitle5"
                                subtitle5="5. Scalable Architecture"
                                subdesc5="desc5"
                                desc5="Build scalable applications that can grow with your business. Our platform provides the tools and infrastructure
                                 needed to handle increased workloads and user demands."



                                // subtitle1="subtitle2"
                                // subtitle1=""
                                
                                
                                // desc1= "  "
                                // desc2="abcd"

                            />
                            {/* Section Title End */}
                            <div className="btn-part mt-46">
                                <Link to="/contact">
                                    <a className="readon learn-more">Contact Us</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="Software Details" />
                            </div>
                        </div>
                    </div>
                    {/*  Below of Description start */}
                    <div className="rs-services style5 pt-120 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Expert Peoples"
                                        Text="Empower your project with our team of seasoned software development experts, 
                                        bringing a wealth of experience. "
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="First Growing Process"
                                        Text="Embark on a transformative journey with our software development team, guiding 
                                        you through the initial growth phase with agile methodologies. "
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Creative Ideas"
                                        Text="Embark on a captivating voyage through our software development atelier, where 
                                        creativity converges with code. "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}

            {/* Why Choose US Section Start */}
            <div className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-46 md-pr-15 md-mb-50">
                            <div className="choose-img">
                                <img className="main" src={whyChooseUsImg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text choose"
                                subtitle="Why Choose Us"
                                titleClass="title pb-20"
                                title="We Are Increasing Business Success With Technology "
                                descClass="desc"
                                description="Stay ahead of the curve with our adoption of the latest web technologies and development methodologies, 
                                We work closely with you throughout the development process, ensuring your feedback shapes the final product."
                            />
                            {/* Section Title End */}
                            <div className="row">
                                <div className="col-lg-10">
                                    {/* Skill Bar Start */}
                                    <div className="rs-skillbar style1 modify1">
                                        <div className="cl-skill-bar">
                                            <ProgressBar
                                                completed={92}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#03228f"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Software Development"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={80}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#03228f"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Cyber Security"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={95}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#03228f"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Artificial Intelligence"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={78}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#03228f"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Web Development"
                                                ProgressParent="main-div"
                                            />
                                        </div>
                                    </div>
                                    {/* Skill Bar End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Choose US Section End */}

            {/* Counter Section Start */}
            <CounterStyleThree />
            {/* Counter Section Start */}

           

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
             <Footer />
            <SearchModal />
        </React.Fragment>

    );
}

export default SoftwareDevelopment