import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {

	const [home, setHome] = useState(false)
	const [homeMultipage, setHomeMultipage] = useState(false)
	const [homeOnepage, setHomeOnepage] = useState(false)
	const [about, setAbout] = useState(false)
	const [services, setServices] = useState(false)
	const [blog, setBlog] = useState(false)
	const [pages, setPages] = useState(false)
	const [servicePages, setServicePages] = useState(false)
	const [caseStudies, setCaseStudies] = useState(false)
	const [shopPages, setShopPages] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'homeMultipage') {
			setHome(true)
			setHomeMultipage(!homeMultipage)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'homeOnepage') {
			setHome(true)
			setHomeMultipage(false)
			setHomeOnepage(!homeOnepage)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(!about)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'services') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(!services)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(!blog)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'pages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(!pages)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'servicePages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(!servicePages)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'caseStudies') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(false)
			setCaseStudies(!caseStudies)
			setShopPages(false)
		}
		else if (menu === 'shopPages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(!shopPages)
		}
	};

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
				
					<ul className="nav-menu">
						<li className={home}>
							<Link onClick={() => { openMobileMenu('home'); }} className={activeMenu === "/" ? "active-menu" : ""}>Home</Link>
							
						</li>
						<li>
							<Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
						</li>
						<li className={services ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('services'); }}>Service</Link>
							<ul className={services ? "sub-menu current-menu" : "sub-menu"}>
								  <li>
                      				  <Link to="/ServiceDetails/SoftwareDevelopment" className={activeMenu === "/software-development" ? "active-menu" : ""}>Software Development</Link>
								</li>
									<li>
										<Link to="/ServiceDetails/WebDevelopment" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
									</li>
									<li>
										<Link to="ServiceDetails/AppDevelopment" className={activeMenu === "/AppDevelopment" ? "active-menu" : ""}>App Development</Link>
									</li>
									<li>
										<Link to="/ServiceDetails/Cloud" className={activeMenu === "/cloud-and-devops" ? "active-menu" : ""}>Cloud and DevOps</Link>
									</li>
									<li>
										<Link to="/ServiceDetails/Product" className={activeMenu === "/project-design" ? "active-menu" : ""}>Project Development</Link>
									</li>
									<li>
										<Link to="/ServiceDetails/Custom" lassName={activeMenu === "/data-center" ? "active-menu" : ""}>Custom Development</Link>
									</li>
							</ul>
						</li>
						
								
						<li className={blog}>
							<Link to="/industries" className={activeMenu === "/industries" ? "active-menu" : ""}>Industries</Link>
								{/* <ul className="sub-menu">
									<li>
										<Link to="/industries-det" className={activeMenu === "/Industries" ? "active-menu" : ""}>Blog</Link>
									</li>
									<li>
										<Link to="/blog/blog-details" className={activeMenu === "/blog-details" ? "active-menu" : ""}>Industries details</Link>
									</li>
								</ul> */}
						</li>
						<li>
							<Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;