import { Link } from 'react-router-dom';

const SingleServiceFlip = (props) => {
	return(
        <div className={props.itemClass}>
            <div id=" ind" className="flip-box-wrap">
                <div className="front-part">
                    <div className="front-content-part">
                        <div className="front-icon-part">
                            <div className="icon-part">
                                <img
                                    src={props.serviceImage}
                                    alt={props.Title}
                                />
                            </div>
                        </div>
                        <div className="front-title-part">
                            <h3 className="title">{props.Title1}</h3>
                            <h3 className="title">{props.Title2}</h3>
                            <h3 className="title">{props.Title3}</h3>
                            <h3 className="title">{props.Title4}</h3>
                            <h3 className="title">{props.Title5}</h3>
                            <h3 className="title">{props.Title6}</h3>
                            <h3 className="title">{props.IndTitle}</h3>
                        </div>
                        <div className="front-desc-part">
                            <p>
                                {props.TextDesc}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="back-front back-front-home in">
                    <div className="back-front-content">
                        <div className="back-title-part">
                            <h3 className="back-title">
                               
                                <Link to='/ServiceDetails/SoftwareDevelopment'>
                                    {props.Title1}
                                </Link>
                                 <Link to='/ServiceDetails/WebDevelopment'>
                                    {props.Title2}
                                </Link>

                                 <Link to='/ServiceDetails/AppDevelopment'>
                                    {props.Title3}
                                </Link>
                                 <Link to='/ServiceDetails/Cloud'>
                                    {props.Title4}
                                </Link>
                                 <Link to='/ServiceDetails/Product'>
                                    {props.Title5}
                                </Link>
                                 <Link to='/ServiceDetails/Custom'>
                                    {props.Title6}
                                </Link>
                            </h3>
                            <h3 className="back-title">
                               
                                    {props.IndTitle}
                               
                            </h3>
                        </div>
                        <div className="back-desc-part">
                            <p className="back-desc">
                                {props.TextDesc}
                            </p>
                        </div>
                        <div className="back-btn-part">
                            <Link to='/ServiceDetails/SoftwareDevelopment' className={props.ButtonClass1}> {props.ButtonText1}</Link>
                            
                        </div>
                        <div className="back-btn-part">
                            <Link to='/ServiceDetails/WebDevelopment' className={props.ButtonClass2}> {props.ButtonText2}</Link>
                          
                        </div>
                        <div className="back-btn-part">
                            <Link to='/ServiceDetails/AppDevelopment' className={props.ButtonClass3}> {props.ButtonText3}</Link>
                          
                        </div>
                        <div className="back-btn-part">
                            <Link to='/ServiceDetails/Cloud' className={props.ButtonClass4}> {props.ButtonText4}</Link>
                          
                        </div>
                        <div className="back-btn-part">
                            <Link to='/ServiceDetails/Product' className={props.ButtonClass5}> {props.ButtonText5}</Link>
                          
                        </div>
                        <div className="back-btn-part">
                            <Link to='/ServiceDetails/Custom' className={props.ButtonClass6}> {props.ButtonText6}</Link>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default SingleServiceFlip