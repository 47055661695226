const SectionTitle = (props) => {
    const { titleClass, subtitleClass, descClass, secondDescClass, effectClass, indTitle , titleClass1,subtitle1, 
        desc5,desc2,desc3,desc4,subtitle2,subtitle3,subtitle4,subtitle5} = props;
    return (
        <div className={props.sectionClass}>
            { subtitleClass ? <div className={props.subtitleClass}>{props.subtitle}</div> : ''}
            { titleClass ? <div className={props.titleClass}>{props.title}</div> : ''}
            { descClass ? <div className={props.descClass}>{props.description}</div> : ''}
            {titleClass1 ? <span className={props.titleClass1}>{props.title1}</span> : ''} 
            {subtitle1 ? <div className={props.subtitleClass1}>{props.subtitle1}</div> : ''}  
           

            { secondDescClass ? <div className={props.secondDescClass}>{props.secondDescription}</div> : ''}
             {subtitle2 ? <div className={props.subtitleClass2}>{props.subtitle2}</div> : ''}  
             { desc2 ? <div className={props.subdesc2}>{props.desc2}</div> : ''}

            {subtitle3 ? <div className={props.subtitleClass3}>{props.subtitle3}</div> : ''} 
            { desc3 ? <div className={props.subdesc3}>{props.desc3}</div> : ''} 
            {subtitle4 ? <div className={props.subtitleClass4}>{props.subtitle4}</div> : ''} 
            { desc4 ? <div className={props.subdesc4}>{props.desc4}</div> : ''}

            {subtitle5 ? <div className={props.subtitleClass5}>{props.subtitle5}</div> : ''}  
            { desc5 ? <div className={props.subdesc5}>{props.desc5}</div> : ''}


            
            
            
            
            { effectClass ? <div className={props.effectClass}></div> : ''}
            {indTitle ? <div className={props.indTitle}>{props.indTitle}</div> : ''}
        </div>
    );
}

export default SectionTitle