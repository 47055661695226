import React from 'react';
import Header from '../../components/Layout/Header';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import Footer from '../../components/Layout/Footer';
import ScrollToTop from '../../components/Common/ScrollTop';
import SearchModal from '../../components/Layout/Header/SearchModal';



import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlip from '../../components/Service/SingleServiceFlip';

import serviceIcon1 from '../../assets/img/industries/1.png';
import serviceIcon2 from '../../assets/img/industries/2.png';
import serviceIcon3 from '../../assets/img/industries/3.png';
import serviceIcon4 from '../../assets/img/industries/add.png';
// import serviceIcon5 from '../../assets/img/service/5.png';
// import serviceIcon6 from '../../assets/img/service/main-home/6.png';

import shapeImg from '../../assets/img/service/s2.png';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/4.jpg';
import ContactMain from '../industries/ContactMain';

const Blog = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='blog'
                activeMenu='/blog'
            />
            <div>
                <SiteBreadcrumb
                    pageTitle="OUR INDUSTRIES"
                    titleClass="page-title"
                    pageName="Industries"
                    breadcrumbsImg={bannerbg}
                />

                 <div id="rs-service" className="rs-services main-home style2 pt-120 pb-94 md-pt-80 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                        
                    titleClass="title industries"
                    title="Accelerating innovation and developing competitive edge across industries worldwide. Get the best IT solutions and development capabilities to achieve targeted revenue."
                />
                <div className="row">
                    <div className="col-lg-3 col-md-4 mb-26  in">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon1}
                            // serviceURL="contact"
                            IndTitle="FinTech"
                            // TextDesc="FinTech, or financial technology, revolutionizes industries by leveraging innovative digital solutions to enhance and streamline financial services,
                             //ultimately optimizing efficiency and improving customer experiences."
                            // ButtonClass="readon view-more"
                            // ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-3 col-md-4 mb-26 in">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon2}
                            // serviceURL="web-development"
                            IndTitle="Health Care"
                           // TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."
                            // ButtonClass="readon view-more"
                            // ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-3 col-md-4 mb-26 in">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon3}
                            // serviceURL="analytic-solutions"
                            IndTitle="E-Commerce"
                          //  TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."
                            // ButtonClass="readon view-more"
                            // ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-3 col-md-4 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon4}
                            // serviceURL="cloud-and-devops"
                            IndTitle="More"
                           // TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."
                            // ButtonClass="readon view-more"
                            // ButtonText="Get In Touch"
                        />
                    </div>
                    {/* <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon5}
                            // serviceURL="product-design"
                            IndTitle="Product Development"
                            TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."
                            // ButtonClass="readon view-more"
                            // ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon6}
                           // serviceURL="data-center"
                            IndTitle="Data Center"
                            TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."
                            // ButtonClass="readon view-more"
                            // ButtonText="Get In Touch"
                        />
                    </div> */}
                </div>
            </div>
            <div className="shape-animation">
                <div className="shape-part">
                    <img className="dance" src={shapeImg} alt="images" />
                </div>
            </div>
        </div>

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}

            </div>
            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}


export default Blog;

